const pic1 = [
  {
    id: 1,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Astronaut.svg",
  },
  {
    id: 2,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/Astronaut2.svg",
  },
  {
    id: 3,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/Astronaut3.svg",
  },
  {
    id: 4,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/AstronautLove.svg",
  },
  {
    id: 5,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/DiverAstronaut.svg",
  },
  {
    id: 6,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Girl.svg",
  },
  {
    id: 7,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Boy.svg",
  },
];

const pic2 = [
  {
    id: 8,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Jetpack.svg",
  },
  {
    id: 9,
    image: "http://www.makereducacional.com.br/astronaut/avatars/HuntMoon.svg",
  },
  {
    id: 10,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Marmeid.svg",
  },
  {
    id: 11,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Ninja.svg",
  },
  {
    id: 12,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/AstronautBeach.svg",
  },
  {
    id: 13,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/AstronautGuitar.svg",
  },
  {
    id: 14,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/AstronautRocket.svg",
  },
];

const pic3 = [
  {
    id: 15,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Alien.svg",
  },
  {
    id: 16,
    image: "http://www.makereducacional.com.br/astronaut/avatars/AlienCat.svg",
  },
  {
    id: 17,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Aliencorn.svg",
  },
  {
    id: 18,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Allien2.svg",
  },
  {
    id: 19,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/AllienEating.svg",
  },
  {
    id: 20,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/AllienFight.svg",
  },
  {
    id: 21,
    image:
      "http://www.makereducacional.com.br/astronaut/avatars/AllienIceCream.svg",
  },
];

const pic4 = [
  {
    id: 22,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Dog1.svg",
  },
  {
    id: 23,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Dog2.svg",
  },
  {
    id: 24,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Kitten1.svg",
  },
  {
    id: 25,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Kitten2.svg",
  },
  {
    id: 26,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Kitten3.svg",
  },
  {
    id: 27,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Kitten4.svg",
  },
  {
    id: 28,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Kitten5.svg",
  },
];

const pic5 = [
  {
    id: 29,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Axalot.svg",
  },
  {
    id: 30,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Duck.svg",
  },
  {
    id: 31,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Elephant.svg",
  },
  {
    id: 32,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Hen2.svg",
  },
  {
    id: 33,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Monkey1.svg",
  },
  {
    id: 34,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Octopus.svg",
  },
  {
    id: 35,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Snail.svg",
  },
];

const pic6 = [
  {
    id: 36,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Bear.svg",
  },
  {
    id: 37,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Panda1.svg",
  },
  {
    id: 38,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Rabbit.svg",
  },
  {
    id: 39,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Turtle.svg",
  },
  {
    id: 40,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Raccot.svg",
  },
  {
    id: 41,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Sloth.svg",
  },
  {
    id: 42,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Squirrel.svg",
  },
];
const pic7 = [
  {
    id: 43,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Unicorn.svg",
  },
  {
    id: 44,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Dino.svg",
  },
  {
    id: 45,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Helmet.svg",
  },
  {
    id: 46,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Meteor.svg",
  },
  {
    id: 47,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Moon.svg",
  },
  {
    id: 48,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Saturn.svg",
  },
  {
    id: 49,
    image: "http://www.makereducacional.com.br/astronaut/avatars/Spaceship.svg",
  },
];

module.exports = [pic1, pic2, pic3, pic4, pic5, pic6, pic7];
