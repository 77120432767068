<template>
  <div>
    <apexchart
      type="bar"
      :height="$vuetify.breakpoint.smAndDown ? 200 : 350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "App",
  props: {
    chartData: {
      name: [],
      data: [],
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        yaxis: {
          max: 10,
          tickAmount: 5,
        },
        xaxis: {
          tickPlacement: "between",
          position: "bottom",
          tickAmount: 25,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              xaxis: {
                tickAmount: 5,
              },
            },
          },
        ],
        grid: {
          show: true,
        },
        legend: {
          position: "top",
          fontSize: "14px",
        },
        chart: {
          type: "bar",
        },
        menu: {
          offsetY: 10,
          offsetX: 10,
          fontSize: "14px",
          width: 150,
        },
        colors: ["#F9C228"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "80%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        fill: {
          opacity: 1,
        },
        noData: {
          text: this.$t('alerta.alerta_numresultado'),
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "16px",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      setTimeout(() => {
        const { name, data } = this.chartData;
        this.series = [{ name: this.$t('student.stundent_aula.aula_title'), data: data }];
        this.chartOptions = {
          xaxis: {
            categories: name,
          },
        };
      }, 500);
    },
  },
};
</script>

<style></style>
