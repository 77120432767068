<template>
  <div>
    <apexchart
      type="donut"
      :height="$vuetify.breakpoint.smAndDown ? 200 : 350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    chartClass: {},
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        labels: [this.$t('grade.label.label1'), this.$t('grade.label.label2'), this.$t('grade.label.label3')],
        colors: ["#FD80CD", "#1CFEE8", "#ECEFF1"],
        chart: {
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
              labels: {
                show: true,
                name: {
                  color: "#000000",
                  offsetY: 25,
                },
                value: {
                  color: "#000000",
                  fontSize: "32px",
                  fontWeight: 600,
                  offsetY: -15,
                },
                total: {
                  show: true,
                  label: "Total",
                  fontWeight: 400,
                  color: "#000000",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
    };
  },
  created() {
    this.updateClass();
  },
  methods: {
    updateClass() {
      setTimeout(() => {
        this.series = [];
        const data = this.chartClass;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          this.series.push(element);
        }
      }, 500);
    },
  },
};
</script>

<style></style>
