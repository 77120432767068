<template>
  <!-- PERFIL -->
  <v-app class="backcolor">
    <v-col cols="12" md="10" class="mx-auto">
      <v-card elevation="5" class="rounded-xl">
        <v-img
          :height="$vuetify.breakpoint.smAndUp ? '150px' : '80px'"
          :src="profileInfo.cover"
          class="d-flex align-center text-center"
        >
          <v-btn
            fab
            :x-large="$vuetify.breakpoint.smAndUp"
            class="white--text elevation-24"
            color="#00000090"
            @click="editCover()"
          >
            <v-icon>mdi-camera</v-icon>
          </v-btn>
        </v-img>

        <v-row no-gutters class="pa-2" justify="center" align-content="center">
          <v-col cols="5" sm="3" lg="2" order="1" order-sm="1">
            <v-sheet
              class="mt-n16"
              :class="$vuetify.breakpoint.xsOnly ? 'ml-2' : 'ml-3'"
            >
              <v-img
                class="d-flex align-center text-center rounded-circle"
                :src="profileInfo.avatar"
                style="border: 3px solid white"
                aspect-ratio="1"
              >
                <v-btn
                  fab
                  :x-large="$vuetify.breakpoint.smAndUp"
                  class="white--text elevation-24"
                  color="#000000b0"
                  @click="editPicture()"
                >
                  <v-icon>mdi-camera</v-icon>
                </v-btn>
              </v-img>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="7" lg="8" order="3" order-sm="2">
            <div
              :style="
                $vuetify.breakpoint.xsOnly
                  ? 'font-size: 1.2rem'
                  : 'font-size: 1.4rem'
              "
              class="font-weight-bold pl-3"
            >
              {{ profileInfo.student.name }}
            </div>
            <div
              class="pl-3 font-weight-medium"
              :style="$vuetify.breakpoint.smAndUp ? '' : 'font-size: 1rem'"
            >
              {{ profileInfo.student.school.nickname }}
              <span class="px-2" v-if="$vuetify.breakpoint.xsOnly">|</span>
              <br v-if="$vuetify.breakpoint.smAndUp" />
              {{ profileInfo.student.school.city }} -
              {{ profileInfo.student.school.state }}
            </div>
          </v-col>
          <v-col cols="7" sm="2" order="2" order-sm="3"> </v-col>
        </v-row>
      </v-card>
      <v-row class="my-2">
        <v-col cols="12">
          <v-card elevation="5" class="rounded-xl pa-4 fill-height">
            <v-card-title>
              <v-avatar
                v-if="$vuetify.breakpoint.smAndUp"
                size="55"
                class="mr-2"
              >
                <img src="../../assets/Maker_Paper.svg" />
              </v-avatar>
              {{ $t('student.student_detalhesperfil') }}

              <v-spacer> </v-spacer>
              <v-tooltip left max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-icon class="mt-n4" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                {{ $t('student.student_garantia') }}
              </v-tooltip>
            </v-card-title>
            <v-card-text>
              <v-form ref="editData">
                <v-row
                  class="mt-2 mt-sm-8"
                  :no-gutters="$vuetify.breakpoint.xsOnly"
                >
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field
                      :label="$t('student.student_label.label1')"
                      v-model="profileInfo.student.name"
                      color="grey darken-2"
                      readonly
                      outlined
                      append-icon="mdi-card-account-details-outline"
                      class="myDisabled"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field
                      :label="$t('student.student_label.label2')"
                      v-model="profileInfo.student.user"
                      color="grey darken-2"
                      readonly
                      outlined
                      append-icon="mdi-account-outline"
                      class="myDisabled"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field
                      :label="$t('student.student_label.label3')"
                      v-model="profileInfo.student.school.name"
                      color="grey darken-2"
                      readonly
                      outlined
                      append-icon="mdi-school-outline"
                      class="myDisabled"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field
                      :label="$t('student.student_label.label4')"
                      v-model="profileInfo.student.team.name"
                      color="grey darken-2"
                      readonly
                      outlined
                      append-icon="mdi-account-group-outline"
                      class="myDisabled"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field
                      :label="$t('student.student_label.label5')"
                      v-model="profileInfo.student.phone"
                      color="darkblue"
                      append-icon="mdi-cellphone"
                      v-mask="'(##) #########'"
                      outlined
                      :rules="[rules.required, rules.phoneLength(13)]"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field
                      :label="$t('student.student_label.label6')"
                      v-model="profileInfo.student.user"
                      color="darkblue"
                      readonly
                      outlined
                      type="password"
                      @click="editPassword = true"
                      append-icon="mdi-lock-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-text-field
                      :label="$t('student.student_label.label7')"
                      v-model="profileInfo.student.email"
                      color="darkblue"
                      outlined
                      :rules="[rules.required, rules.email]"
                      append-icon="mdi-at"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      top
                      transition="slide-y-reverse-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="computedDateFormatted"
                          :label="this.$t('student.student_minhainformacao.informacao4')"
                          append-icon="mdi-calendar"
                          v-mask="'##/##/####'"
                          :rules="[rules.date]"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @blur="
                            profileInfo.student.birthday = parseDate(
                              computedDateFormatted
                            )
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="profileInfo.student.birthday"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save()"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="mt-n8">
              <v-spacer></v-spacer>
              <v-btn
                right
                rounded
                color="darkpink"
                class="mt-2 white--text px-6"
                @click="confirmSave()"
              >
                {{ $t('botoes.botao_salva') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="profileCover"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '95%'"
      persistent
    >
      <template>
        <v-card class="rounded-lg">
          <v-card-title
            class="
              text-subtitle-1 text-md-h5
              white--text
              text-uppercase
              darkblue
            "
          >
            {{ $t('student.student_capa') }}
          </v-card-title>

          <div class="my-2 my-sm-4 my-md-6 mx-2">
            <v-carousel
              v-model="coverIndex"
              hide-delimiter-background
              :hide-delimiters="$vuetify.breakpoint.smAndDown"
              delimiter-icon="mdi-minus"
              height="100%"
              class="rounded-lg"
              :show-arrows-on-hover="$vuetify.breakpoint.mdAndUp"
            >
              <v-carousel-item v-for="(cov, c) in covers" :key="c">
                <v-img
                  :src="cov.src"
                  eager
                  class="rounded-lg mx-auto"
                  lazy-src="http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Bubbles.svg"
                  :width="$vuetify.breakpoint.xsOnly ? '280px' : ''"
                  :height="$vuetify.breakpoint.xsOnly ? '280px' : ''"
                  position="right center"
                />
              </v-carousel-item>
            </v-carousel>
          </div>

          <v-card-actions class="px-4 pb-4">
            <v-spacer></v-spacer>
            <v-btn
              text
              rounded
              color="darkpink"
              class="px-4"
              @click="profileCover = false"
              >{{ $t('botoes.botao_cancelar') }}</v-btn
            >
            <v-btn
              dark
              rounded
              color="darkpink"
              class="px-4"
              @click="confirmCover(covers[coverIndex])"
            >
              {{ $t('botoes.botao_selecionar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      v-model="profileAvatar"
      transition="dialog-bottom-transition"
      :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '95%'"
    >
      <template>
        <v-card class="rounded-lg">
          <v-card-title class="white--text darkblue">
            <div class="text-subtitle-2 mt-2">
              <span
                class="text-h6 text-md-h5 font-weight-medium text-uppercase"
                style="line-height: 0"
              >
                {{ $t('student.student_avatar') }}
              </span>
              <br />
              <!-- <span class="font-weight-regular" style="font-size: 1.05rem"
                >Clique duas vezes na imagem que deseja selecionar como
                avatar</span
              > -->
            </div>
          </v-card-title>

          <v-window v-model="onboarding" touchless class="mt-2 mt-md-8">
            <v-window-item v-for="(n, index) in lengthW" :key="index">
              <v-row align="center" justify="center" no-gutters>
                <v-col cols="2" sm="1" class="text-center">
                  <v-btn fab text @click="prev()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="8" sm="10">
                  <div>
                    <v-item-group v-model="choosed">
                      <v-container>
                        <v-row align="center" justify="center">
                          <v-col
                            cols="6"
                            sm="3"
                            v-for="avat in picOptions"
                            :key="avat.id"
                            class="text-center"
                          >
                            <v-item v-slot="{ active, toggle }">
                              <v-scroll-y-transition>
                                <div v-if="active">
                                  <v-avatar size="100%">
                                    <v-img
                                      :src="avat.image"
                                      :aspect-ratio="1"
                                      class="selected cursor"
                                    />
                                  </v-avatar>
                                </div>
                                <div v-else>
                                  <v-avatar size="100%">
                                    <v-img
                                      :src="avat.image"
                                      :aspect-ratio="1"
                                      class="unselected cursor"
                                      @click="toggle"
                                      v-on:click="selectAvat(avat)"
                                    />
                                  </v-avatar>
                                </div>
                              </v-scroll-y-transition>
                            </v-item>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-item-group>
                  </div>
                </v-col>
                <v-col cols="2" sm="1" class="text-center">
                  <v-btn fab text @click="next()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-window-item>
          </v-window>
          <v-card-actions class="py-4">
            <v-spacer></v-spacer>
            <v-btn text rounded color="darkpink" @click="profileAvatar = false">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn
              dark
              rounded
              color="darkpink"
              class="px-4"
              @click="setAvatar()"
            >
              {{ $t('botoes.botao_selecionar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      v-model="editPassword"
      persistent
      :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '90%'"
      u
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{ $t('label.label_senha.senha_redefinir') }}
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editPass">
            <v-row no-gutters>
              <v-col cols="12" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_redefinir')"
                  outlined
                  :rules="[rules.required, rules.length(4), rules.verify]"
                  :type="showPassword1 ? 'text' : 'password'"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  placeholder="Insira sua senha"
                  v-model="currentPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_nova')"
                  outlined
                  :rules="checkPasswordRules.concat(passwordCheckRule)"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  :placeholder="$t('label.label_senha.senha_insera')"
                  v-model="newPassword"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_confirmar')"
                  outlined
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  :type="showPassword3 ? 'text' : 'password'"
                  :append-icon="showPassword3 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword3 = !showPassword3"
                  :placeholder="$t('label.label_senha.senha_insera')"
                  v-model="confirmPassword"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4"
            text
            @click="editPassword = false"
          >
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            rounded
            :loading="loading"
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="editPasswordConfirm()"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmAlt" max-width="500px">
      <v-card
        class="rounded-lg py-3 darkblue--text"
        style="border-left: 12px inset #1670e8"
      >
        <v-card-title>
          <v-icon left large color="darkblue">mdi-alert-circle</v-icon>
          {{ $t('botoes.botao_salva') }}
        </v-card-title>
        <v-card-text class="black--text text-body-2">
          {{ $t('alerta.alerta_atualizacao') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn color="darkblue" dark rounded class="px-3" @click="saveEdit()">
            {{ $t('botoes.botao_confirma') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon
        :large="$vuetify.breakpoint.smAndUp ? true : false"
        color="success"
        left
        >mdi-check-circle
      </v-icon>
      <span class="black--text text-subtitle-1">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const Pictures = require("./ProfilePictures.js");
const CoversPic = require("./CoverPictures.js");

export default {
  data() {
    return {
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        phoneLength: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_telefone.telefone1')} ${len - 5} ${this.$t('regras.regra_telefone.telefone2')}`,
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        verify: () => this.message || this.$t('regras.regra_senhaincorreta'),
        none: true,
      },
      confirmPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
      checkPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],

      newPassword: "",
      confirmPassword: "",
      currentPassword: "",

      coverIndex: null,
      covers: CoversPic[0],
      editAvatar: Pictures,
      picOptions: null,
      lengthW: null,
      onboarding: 0,
      profileAvatar: false,
      profileCover: false,
      choosed: false,
      profileInfo: null,
      show: false,
      editPassword: false,
      showPassword1: false,
      showPassword2: false,
      showPassword3: false,
      activePicker: null,
      menu: false,
      dateFormatted: null,
      confirmAlt: false,
      successSnackbar: false,
      message: true,
      loading: false,
      setingAvatar: null,
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.profileInfo.student.birthday);
    },
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || this.$t('login.login_painel.coincidem');
    },
    passwordCheckRule() {
      return () =>
        this.newPassword !== this.currentPassword ||
        this.$t('login.login_painel.senhaigual');
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date() {
      this.dateFormatted = this.formatDate(this.profileInfo.student.birthday);
    },
  },
  created() {
    this.confirmOption();
    this.profileInfo = this.$parent.$parent.$parent.profile;
  },
  methods: {
    async editPasswordConfirm() {
      let validation = this.$refs.editPass.validate();

      if (validation) {
        let data = {
          newPassword: this.confirmPassword,
          currentPassword: this.currentPassword,
        };

        let res = await axios.post(
          `${url}/students/alterConfirmedPassword`,
          data,
          {
            headers: {
              authorization: token,
            },
          }
        );

        let verify = res.data;

        if (verify.status == "success") {
          this.$refs.editPass.reset();
          this.editPassword = false;
          this.successSnackbar = true;
          this.message = true;
        } else {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.message = false;
            this.$refs.editPass.validate();
            setTimeout(() => {
              this.message = true;
              this.$refs.editPass.validate();
            }, 2500);
          }, 1000);
        }
      }
    },
    confirmSave() {
      this.confirmAlt = true;
    },
    async saveEdit() {
      let validation = this.$refs.editData.validate();

      if (validation) {
        try {
          let data = {
            phone: this.profileInfo.student.phone,
            email: this.profileInfo.student.email,
            birthday: this.profileInfo.student.birthday,
          };
          let id = this.profileInfo.student.id;

          await axios.put(`${url}/students/${id}`, data, {
            headers: {
              authorization: token,
            },
          });

          this.$parent.$parent.$parent.page = this.$t('botoes.botao_perfil');
        } catch (error) {
          console.error(error);
        }
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    save(date) {
      this.$refs.menu.save(date);
    },
    async getProfile() {
      let profile = await axios.get(`${url}/profilesForStudent`, {
        headers: {
          authorization: token,
        },
      });
      this.profileInfo = profile.data;
      this.$parent.$parent.$parent.profile = this.profileInfo;
    },
    selectAvat(res) {
      this.setingAvatar = res;
    },
    async setAvatar() {
      let alt = this.setingAvatar.image;
      await axios.put(
        `${url}/profilesForStudent`,
        { avatar: alt },
        {
          headers: {
            authorization: token,
          },
        }
      );
      this.profileAvatar = false;
      this.setingAvatar = null;
      this.getProfile();
    },
    async confirmCover(res) {
      let alt = res.src;
      await axios.put(
        `${url}/profilesForStudent`,
        { cover: alt },
        {
          headers: {
            authorization: token,
          },
        }
      );
      this.profileCover = false;
      this.getProfile();
    },
    confirmOption() {
      switch (this.onboarding) {
        case 0:
          this.picOptions = this.editAvatar[0];
          break;
        case 1:
          this.picOptions = this.editAvatar[1];
          break;
        case 2:
          this.picOptions = this.editAvatar[2];
          break;
        case 3:
          this.picOptions = this.editAvatar[3];
          break;
        case 4:
          this.picOptions = this.editAvatar[4];
          break;
        case 5:
          this.picOptions = this.editAvatar[5];
          break;
        case 6:
          this.picOptions = this.editAvatar[6];
          break;
        case 7:
          this.picOptions = this.editAvatar[7];
          break;
      }
    },

    editCover() {
      this.profileCover = true;
    },
    editPicture() {
      this.lengthW = this.editAvatar.length;
      this.profileAvatar = true;
    },
    next() {
      this.choosed = false;
      this.onboarding =
        this.onboarding + 1 === this.lengthW ? 0 : this.onboarding + 1;
      this.confirmOption();
    },
    prev() {
      this.choosed = false;
      this.onboarding =
        this.onboarding - 1 < 0 ? this.lengthW - 1 : this.onboarding - 1;
      this.confirmOption();
    },
  },
};
</script>

<style lang="css" scoped>
.selected {
  border: 4px solid #468c00;
}

.unselected {
  transform: none;
  transition: all 0.5s;
}

.cursor {
  cursor: pointer;
}

.myDisabled {
  opacity: 0.6;
}
</style>
