<template>
  <v-app class="backcolor">
    <v-row no-gutters>
      <v-col
        cols="3"
        class="text-center blue white--text"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-avatar class="mt-4" size="180" style="border: 3px solid #ffffff">
          <v-img :src="studentInfo.avatar"></v-img>
        </v-avatar>
        <div class="white--text mt-2 text-h5">
          {{ studentInfo.student.name }}
        </div>
        <v-row>
          <v-col
            cols="10"
            class="mx-auto mt-8 pa-4 darkblue white--text rounded-lg"
          >
            <v-row no-gutters>
              <v-col cols="3" class="mx-auto my-auto">
                <v-icon style="font-size: 2.75rem" class="white--text">
                  mdi-town-hall
                </v-icon>
              </v-col>
              <v-col cols="9" class="mx-auto">
                <div class="font-weight-bold">{{ $t('student.student_detalhes.detalhes_institucao') }}</div>
                <span> {{ studentInfo.student.school.name }} </span>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="10"
            class="mx-auto mt-4 mb-2 pa-4 darkblue white--text rounded-lg"
          >
            <v-row no-gutters>
              <v-col cols="3" class="mx-auto my-auto">
                <v-icon style="font-size: 2.75rem" class="white--text">
                  mdi-school
                </v-icon>
              </v-col>
              <v-col cols="9" class="my-auto mx-auto">
                <div class="font-weight-bold">{{ $t('student.student_headers.headers3') }}</div>
                <span> {{ studentInfo.student.team.name }} </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-img src="../../../assets/Homework.svg"></v-img>
      </v-col>

      <v-col cols="12" md="9" class="mx-auto px-8 py-6">
        <div class="mx-2 text-h6 text-sm-h5 font-weight-bold darkcolor--text">
          {{ $t('student.student_ola') }} {{ studentInfo.student.name }}!
        </div>
        <div
          class="
            mx-2
            text-subtitle-2 text-sm-h6
            font-weight-medium
            darkcolor--text
          "
        >
          {{ $t('student.student_selecaodecurso') }}
        </div>

        <v-row class="mt-4">
          <v-col cols="12" sm="6" lg="4">
            <v-card
              class="darkblue white--text rounded-lg"
              @click="toDetails(defaultCourse.id)"
            >
              <v-img
                :src="defaultCourse.thumbnail"
                height="200px"
                class="rounded-t-lg"
              >
              </v-img>
              <div
                class="font-weight-medium px-4 py-2"
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'font-size: 0.9rem'
                    : $vuetify.breakpoint.lgAndUp
                    ? 'font-size: 1.1rem'
                    : 'font-size: 1rem'
                "
              >
                {{ defaultCourse.name }}
              </div>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            lg="4"
            v-for="course in courses"
            :key="course.id"
          >
            <v-card
              @click="toDetails(course.id)"
              class="darkblue white--text rounded-lg"
            >
              <v-img
                :src="course.thumbnail"
                height="200px"
                class="rounded-t-lg"
              >
              </v-img>
              <div
                class="font-weight-medium px-4 py-2"
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'font-size: 0.9rem'
                    : $vuetify.breakpoint.lgAndUp
                    ? 'font-size: 1.1rem'
                    : 'font-size: 1rem'
                "
              >
                {{ course.name }}
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-card
              @click="toOtherCourses()"
              class="
                pa-4
                rounded-lg
                d-flex
                justify-center
                aling-space-around
                flex-column
                elevation-6
                text-center
              "
              color="rgba(25, 124, 225, 0.2)"
              style="width: 100%; height: 100%"
            >
              <v-img
                max-width="75px"
                max-height="75px"
                :aspect-ratio="1 / 1"
                class="mx-auto"
                cover
                src="../../../assets/OtherCourses.svg"
              ></v-img>
              <div
                class="darkcolor--text font-weight-bold"
                style="font-size: 1.5rem"
              >
                {{ $t('student.student_outrocurso') }}
              </div>

              <div
                class="darkblue--text font-weight-medium"
                style="font-size: 0.9rem"
              >
                {{ $t('student.student_confira') }}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const axios = require("axios");
export default {
  data() {
    return {
      courses: [],
      studentInfo: null,
      defaultCourse: [],
    };
  },
  created() {
    this.studentInfo = this.$parent.$parent.$parent.profile;

    this.defaultCourse = this.studentInfo.student.team.Course;
    this.getCursos();
  },
  methods: {
    async getCursos() {
      let res = await axios.get(`${url}/coursesOfStudents`, {
        headers: {
          authorization: token,
        },
      });

      this.courses = res.data;
    },

    toDetails(id) {
      this.$parent.$parent.$parent.gradeCourseId = id;
      this.$parent.$parent.$parent.page = this.$t('teams.team_tab.tab1');
    },

    toOtherCourses() {
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu2');
    },
  },
};
</script>

<style lang="css" scoped></style>
