<template>
  <div>
    <apexchart
      type="area"
      :height="$vuetify.breakpoint.smAndDown ? 200 : 350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "App",
  props: {
    loginChart: {
      name: [],
      data: [],
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        colors: ["#197CFF"],
        chart: {
          type: "area",
          stacked: true,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 0.4,
            stops: [0, 100],
          },
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (value) {
              var date = new Date(value);
              var day = date.getDate() + 1;
              var month = date.getMonth() + 1;
              var monthsAbbrPt = [
                this.$t('admin.admin_calendarioabb.Janeiro'),
                this.$t('admin.admin_calendarioabb.Fevereiro'),
                this.$t('admin.admin_calendarioabb.Março'),
                this.$t('admin.admin_calendarioabb.Abril'),
                this.$t('admin.admin_calendarioabb.Maio'),
                this.$t('admin.admin_calendarioabb.Junho'),
                this.$t('admin.admin_calendarioabb.Julho'),
                this.$t('admin.admin_calendarioabb.Agosto'),
                this.$t('admin.admin_calendarioabb.Setembro'),
                this.$t('admin.admin_calendarioabb.Outubro'),
                this.$t('admin.admin_calendarioabb.Novembro'),
                this.$t('admin.admin_calendarioabb.Dezembro'),
              ];
              var monthAbbrPt = monthsAbbrPt[month];
              return `${day} ${monthAbbrPt}`; // Format the date in Brazilian date format
            },
          },
        },
      },
    };
  },
  created() {
    this.updateLogin();
  },
  methods: {
    updateLogin() {
      setTimeout(() => {
        const { name, data } = this.loginChart;

        this.series = [{ name: this.$t('student.student_acesso.acesso'), data: data }];
        this.chartOptions = {
          xaxis: {
            categories: name,
          },
        };
      }, 500);
    },
  },
};
</script>

<style></style>
