const cov1 = [
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BluePattern.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_PinkPattern.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_YellowPattern.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_CyanPattern.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Alien.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BlackHole.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Bubbles.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Butterfly.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Canoe.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Dome.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Fish.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Hambuguer.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Lantern.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Painting.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Rocket.svg",
  },
  {
    src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Yoga.svg",
  },
  // {
  //   src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Space.svg",
  // },
  // {
  //   src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Farm.svg",
  // },
  // {
  //   src: "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_Theater.svg",
  // },
];

module.exports = [cov1];
