<template>
  <v-app id="inspire">
    <v-app-bar app dark src="../assets/Top_Bar-03.svg">
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        v-if="page != $t('admin.admin_menu.menu23')"
      ></v-app-bar-nav-icon>
      <v-spacer />
      <v-toolbar-title class="lign-center text-center">{{ $t('regisResponsavel.reponsavel_title') }}<br>{{page}}</v-toolbar-title>
      <v-spacer />
      <v-btn icon disabled> </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-sheet>
        <v-img
          class="pa-4"
          :src="profile.cover"
          max-height="130px"
          position="right center"
        >
          <v-avatar size="70">
            <v-img
              class="rounded-circle"
              :src="profile.avatar"
              aspect-ratio="1"
            ></v-img>
          </v-avatar>
          <div class="pt-2 font-weight-medium white--text">
            {{ profile.student.user }}
          </div>
        </v-img>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group color="blue">
          <v-list-item
            v-for="[icon, text] in links"
            :key="icon"
            @click="
              drawer = false;
              page = text;
            "
            link
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <div class="logoutStyle">
          <v-list-item
            @click="
              logoutDialog = true;
              drawer = false;
            "
          >
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ $t('botoes.botao_sair') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="logoutDialog" persistent max-width="320">
      <v-card elevation="15" class="rounded-xl py-4 px-2">
        <v-img src="../assets/HelloCircle2.svg" width="85px" class="mx-auto" />
        <v-card-title style="font-size: 1.1rem" class="mt-n3">
          {{ $t('admin.admin_sair.sair1') }}
        </v-card-title>
        <v-card-text style="font-size: 0.9rem" class="mt-n2">
          {{ $t('admin.admin_sair.sair2') }}
        </v-card-text>
        <v-card-actions class="mt-n2">
          <v-row no-gutters justify="center">
            <v-col cols="12" class="pb-3">
              <v-btn
                rounded
                color="darkblue"
                large
                dark
                block
                @click="logout()"
              >
                {{ $t('botoes.botao_sair') }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                outlined
                rounded
                large
                color="darkblue"
                dark
                block
                @click="logoutDialog = false"
              >
                {{ $t('botoes.botao_cancelar') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="firstAccess"
      persistent
      :max-width="$vuetify.breakpoint.lgAndUp ? '60%' : '90%'"
      :fullscreen="$vuetify.breakpoint.xsOnyl"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          {{ $t('label.label_senha.senha_redefinir') }}
        </v-card-title>
        <v-card-text class="pt-6">
          <v-form ref="editPass">
            <v-row no-gutters>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_nova')"
                  outlined
                  :rules="[rules.required, rules.length(4)]"
                  :type="showPassword1 ? 'text' : 'password'"
                  :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword1 = !showPassword1"
                  :placeholder="$t('label.label_senha.senha_inserasenha')"
                  v-model="password"
                />
              </v-col>
              <v-col cols="12" sm="6" class="px-2">
                <v-text-field
                  color="darkblue"
                  :label="$t('label.label_senha.senha_confirmar')"
                  outlined
                  :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
                  :type="showPassword2 ? 'text' : 'password'"
                  :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPassword2 = !showPassword2"
                  :placeholder="$t('label.label_senha.senha_confirmarsua')"
                  v-model="confirmPassword"
                />
              </v-col>
              <v-row no-gutters>
                <v-checkbox v-model="enabled" color="darkblue">
                  <template v-slot:label>
                    <div>
                      {{ $t('hometeacher.hometeacher_termo.termo1') }}
                      <a
                        target="_blank"
                        href="https://makereducacional.com.br/termsofuse"
                        class="darkblue--text"
                        @click.stop
                      >
                        {{ $t('hometeacher.hometeacher_termo.termo2') }}
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-4 pb-4 mt-n6">
          <v-spacer></v-spacer>
          <v-btn
            rounded
            color="darkpink"
            class="white--text px-4 ml-2"
            @click="editPassword()"
            :disabled="!enabled"
          >
            {{ $t('botoes.botao_alterar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-main class="backcolor">
      <!-- Aqui -->
      <ParentCourse v-if="page == $t('admin.admin_menu.menu3')" />
      <SParentlass v-if="page == $t('admin.admin_menu.menu18')" />
      <ParentEvents v-if="page == $t('admin.admin_menu.menu7')" />
      <ParentGrades v-if="page == $t('admin.admin_menu.menu20')" />
      <ParentDetails v-if="page == $t('teams.team_tab.tab1')" />
      <ParentProfileFriend v-if="page == $t('admin.admin_menu.menu21')" />
      <ParentOtherCourseClasses v-if="page == $t('admin.admin_menu.menu22')" />
      <ParentProfileDetails v-if="page == $t('admin.admin_menu.menu23')" />
      <ParentMaterialExtra v-if="page == $t('admin.admin_menu.menu24')" />
      <router-view @message="setMessage" />
    </v-main>
  </v-app>
</template>

<script>
import ParentCourse from "../components/Parent/ParentCourse.vue";
import ParentEvents from "../components/Parent/ParentEvents.vue";
import ParentGrades from "../components/Parent/Grades/ParentGrades.vue";
import ParentDetails from "../components/Parent/Grades/ParentDetails.vue";
import ParentProfileFriend from "../components/Parent/ParentProfileFriend.vue";
import ParentProfileDetails from "../components/Parent/ParentProfileDetails.vue";
import ParentMaterialExtra from "../components/Parent/ParentMaterial.vue";

const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");

export default {
  name: "Student",
  data: () => ({
    gradeCourseId: null,
    enabled: false,
    emailNews: false,
    firstAccess: false,
    showPassword1: false,
    showPassword2: false,
    rules: {
      length: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
      phoneLength: (len) => (v) =>
        (v || "").length >= len ||
        `${this.$t('regras.regra_telefone.telefone1')} ${len - 5} ${this.$t('regras.regra_telefone.telefone2')}`,
      required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
    },
    confirmPasswordRules: [(v) => !!v || this.$t('regras.regra_obrigatorio')],
    password: "",
    confirmPassword: "",
    drawer: null,
    logoutDialog: false,
    links: [],
    page: "",
    exercisesByClassId: null,
    profile: { student: { user: "Aluno Maker Educacional" } },
    friend: {},
    courseId: null,
    toclassOtherCourse: false,
  }),
  components: {
    ParentCourse,
    ParentEvents,
    ParentGrades,
    ParentDetails,
    ParentProfileFriend,
    ParentProfileDetails,
    ParentMaterialExtra,
  },
  beforeRouteLeave(to, from, next) {
    if (confirm(this.$t('alerta.alerta_sair.sair3'))) {
      next(); // Permite a mudança de rota
    } else {
      next(false); // Cancela a mudança de rota
    }
  },
  mounted() {
    document.title = this.$t('student.student_portalaluno');
    this.links = [
      ["mdi-book-open-variant", this.$t('admin.admin_menu.menu3')], //Revisão, Atividades e Extras por aula.
      ["mdi-school", this.$t('admin.admin_menu.menu20')], //Resultado do questionário das aulas e progresso em outras atividades.
      ["mdi-archive", this.$t('admin.admin_menu.menu24')], //Materiais cadastrados pelo professor
      ["mdi-star", this.$t('admin.admin_menu.menu7')], // Atividades do aluno e do portal
    ],
    this.page = this.$t('admin.admin_menu.menu3')
  },
  created() {
    this.getProfile();
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || this.$t('login.login_painel.coincidem');
    },
  },
  methods: {
    changeMenuLicensed() {
      if (this.profile.student.school.licensed) {
        this.links.splice(0, 1);
        this.links.splice(1, 1);
        this.page = this.$t('admin.admin_menu.menu2');
      }
    },
    async getProfile() {
      let profile = await axios.get(`${url}/profilesForStudent`, {
        headers: {
          authorization: token,
        },
      });
      let parent = await axios.get(`${url}/VerifNewAcesssParent`, {
        headers: {
          authorization: token,
        },
      });
      if (profile.data == null) {
        this.firstAccess = true;
        profile.data = {
          avatar:
            "http://www.makereducacional.com.br/astronaut/avatars/Astronaut.svg",
          cover:
            "http://www.makereducacional.com.br/astronaut/covers/CoverPhoto_BluePattern.svg",
          student: { user: "Aluno Maker Educacional" },
        };
      }
      this.profile = profile.data;
      this.page =this.$t('admin.admin_menu.menu20');

      if(parent.data.result[0].acceptedTerms == null){
        this.firstAccess = true;
      }

      if (
        this.profile.student.acceptedTerms == null ||
        this.profile.student.acceptedTerms == 0
      ) {
        this.firstAccess = true;
      }
      this.changeMenuLicensed();
    },

    setMessage(msg) {
      this.page = msg;
    },
    toLogin() {
      this.$router.go("/login");
    },
    close() {
      // destroy the vue listeners, etc
      this.$destroy();
      // remove the element from the DOM
    },
    logout() {
      this.logoutDialog = false;
      localStorage.removeItem("accessToken");
      this.toLogin();
    },

    async editPassword() {
      let validation = this.$refs.editPass.validate();

      if (validation) {
        try {
          let data = {
            password: this.confirmPassword,
            acceptedTerms: this.enabled,
          };

          await axios.post(`${url}/UpdateConfirmTermo`, data, {
            headers: {
              authorization: token,
            },
          });
          this.getProfile();
          this.firstAccess = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style>
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f128;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(121, 121, 121);
}

.logoutStyle {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

a:link {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
