<template>
  <div class="ma-6">
    <v-row>
      <v-col v-for="fil in files" :key="fil.id" cols="12" sm="6" lg="4">
        <v-card
          height="100%"
          class="pa-6 d-flex flex-row align-center justify-start rounded-lg"
        >
          <div class="text-center">
            <v-icon :color="fil.color" size="60">
              {{ fil.icon }}
            </v-icon>
          </div>
          <div class="d-flex flex-column ml-8">
            <span
              class="font-weight-bold"
              style="font-size: 1.6rem"
              v-text="fil.name"
            />
            <span
              class="mt-4"
              style="font-size: 1rem"
              v-text="fil.description"
            />
            <span
              v-if="fil.size"
              v-text="getByteSize(fil.size)"
              class="text--disabled font-weight-medium"
              style="font-size: 0.75rem"
            />

            <div class="text-center" v-if="fil.icon !== 'mdi-play-box'">
              <v-btn
                :color="fil.color"
                dark
                small
                class="mt-6"
                rounded
                @click="openURL(fil.uri)"
              >
                {{ $t('botoes.botao_visualizar') }}
              </v-btn>
            </div>
            <div class="text-center" v-if="fil.icon === 'mdi-play-box'">
              <v-btn
                :color="fil.color"
                dark
                small
                class="mt-6"
                rounded
                @click="openVideo(fil)"
              >
                {{ $t('botoes.botao_visualizar') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="files.length === 0" cols="12" class="mx-auto">
        <v-img
          src="../../assets/NotebookAstronaut.svg"
          max-width="30vw"
          class="mx-auto"
        />
        <div
          class="
            text-center text-h6 text-md-h5 text-lg-h4
            font-weight-bold
            mt-2
          "
          style="color: #491670"
        >
          <span> {{ $t('alerta.alerta_materialstudent') }} </span>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="videoDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          <span class="font-weight-bold">
            {{ videoData.name }}
          </span>
          <span
            v-text="`(${getByteSize(videoData.size)})`"
            class="font-weight-medium ml-2"
            style="font-size: 0.9rem"
          />
          <v-spacer></v-spacer>
          <v-icon @click="videoDialog = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="mb-4">
            <span class="black--text">{{ $t('homework.homework_detalhes.detalhes3') }} </span>
            {{ videoData.description }}
          </div>
          <div class="d-flex justify-center align-center">
            <vimeo-player
              ref="player"
              :video-url="`https://player.vimeo.com/video/${videoData.uri}`"
              @ready="onReady"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

export default {
  data() {
    return { files: [], teamId: null, videoDialog: false, videoData: [] };
  },
  created() {
    this.teamId = this.$parent.$parent.$parent.profile.student.teamId;
    this.getFiles(this.teamId);
  },
  methods: {
    async getFiles(id) {
      try {
        let res = await axios.get(
          `${url}/teacherTeamFiles/student/${id}`,
          authorization
        );

        this.files = res.data;

        this.getType();
      } catch (error) {
        console.error(error);
      }
    },
    getByteSize(byte_value) {
      let converted_value = null;
      let data_type = "";
      if (byte_value < 1024) {
        data_type = "bytes";
        converted_value = byte_value;
      } else if (byte_value < 1024 * 1024) {
        data_type = "KB";
        converted_value = (byte_value / 1024).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024) {
        data_type = "MB";
        converted_value = (byte_value / (1024 * 1024)).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024 * 1024) {
        data_type = "GB";
        converted_value = (byte_value / (1024 * 1024 * 1024)).toFixed(2);
      } else {
        data_type = "TB";
        converted_value = (byte_value / (1024 * 1024 * 1024 * 1024)).toFixed(2);
      }
      let response = converted_value + " " + data_type;
      return response;
    },
    getType() {
      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];

        switch (element.type) {
          case ".pdf":
            element.icon = "mdi-file-pdf-box";
            element.color = "#A60000";
            break;
          case ".docx":
          case ".docm":
          case ".dotx":
          case ".dotm":
          case ".txt":
            element.icon = "mdi-file-word-box";
            element.color = "#0F3C8A";
            break;
          case ".xlsx":
          case ".xlsm":
          case ".xltx":
          case ".xltm":
          case ".xlsb":
          case ".xlam":
            element.icon = "mdi-file-excel-box";
            element.color = "#1E6C41";
            break;
          case ".pptx":
          case ".pptm":
          case ".potx":
          case ".potm":
          case ".ppam":
          case ".ppsx":
          case ".ppsm":
          case ".sldx":
          case ".sldm":
          case ".thmx":
            element.icon = "mdi-file-powerpoint-box";
            element.color = "#B43416";
            break;
          case ".mp4":
          case ".mov":
          case ".wmv":
          case ".avi":
          case ".avchd":
          case ".flv":
          case ".f4v ":
          case ".swf":
          case ".mkv":
          case ".webm":
          case ".html5":
          case ".mpeg-2":
            element.icon = "mdi-play-box";
            element.color = "#C31E1D";
            break;
          case ".png":
          case ".jpg":
          case ".jpeg":
          case ".gif":
          case ".bmp":
          case ".psd":
          case ".tiff":
          case ".svg":
          case ".raw":
          case ".webp":
            element.icon = "mdi-image";
            element.color = "#0B9E4C";
            break;
          case ".zip":
          case ".arj":
          case ".cab":
          case ".rar":
          case ".tar":
          case ".z":
          case ".gz":
          case ".taz ":
          case ".tgz":
          case ".gzip":
            element.icon = " mdi-zip-box";
            element.color = "#754668";
            break;
          case ".link":
            element.icon = "mdi-link";
            element.color = "#4169E1";
            break;
          default:
            element.icon = "mdi-file";
            element.color = "darkblue";
            break;
        }
      }
    },
    onReady() {
      this.playerReady = true;
    },
    openURL(url) {
      window.open(url);
    },
    openVideo(link) {
      this.videoDialog = true;

      this.videoData = link;
    },
  },
};
</script>

<style></style>
