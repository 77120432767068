<template>
  <!-- PERFIL -->
  <v-app class="backcolor">
    <v-col cols="12" md="10" class="mx-auto">
      <v-card elevation="5" class="rounded-xl">
        <v-img
          :height="$vuetify.breakpoint.smAndUp ? '150px' : '80px'"
          :src="profile.cover"
        >
        </v-img>

        <v-row no-gutters class="pa-2" justify="center" align-content="center">
          <v-col align-self="start" cols="5" sm="3" lg="2">
            <v-sheet
              class="mt-n16"
              :class="$vuetify.breakpoint.xsOnly ? 'ml-2' : 'ml-3'"
            >
              <v-img
                class="rounded-circle"
                :src="profile.avatar"
                style="border: 3px solid white"
                aspect-ratio="1"
              ></v-img>
            </v-sheet>
          </v-col>
          <v-col>
            <div
              :style="
                $vuetify.breakpoint.xsOnly
                  ? 'font-size: 1rem'
                  : 'font-size: 1.4rem'
              "
              class="font-weight-bold pl-3"
            >
              {{ profile.name }}
            </div>
            <div
              v-if="$vuetify.breakpoint.smAndUp"
              class="pl-3 font-weight-medium"
              :style="$vuetify.breakpoint.smAndUp ? '' : 'font-size: 0.9rem'"
            >
              {{ profile.nickname }}
            </div>
            <div
              class="pl-3"
              :style="$vuetify.breakpoint.smAndUp ? '' : 'font-size: 0.8rem'"
            >
              {{ profile.city }} - {{ profile.state }}
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="my-2">
        <v-col cols="12" lg="6">
          <v-card elevation="5" class="rounded-xl pa-6">
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <v-card-title>
                  <v-avatar size="35" class="mr-2">
                    <img src="../../assets/trofeu.png" />
                  </v-avatar>
                  {{ $t('student.student_conquista.title') }}
                  <!-- <v-icon right color="black">mdi-trophy-variant</v-icon> -->
                </v-card-title>

                <v-card
                  elevation="3"
                  class="my-3 rounded-lg"
                  :color="classColors[2].back"
                >
                  <v-list-item>
                    <v-list-item-avatar
                      class="my-2"
                      size="80"
                      style="border: 3px solid #f9c228"
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <v-img :src="profile.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="font-weight-bold" style="font-size: 1rem">
                        {{ $t('student.student_parabens') }}
                      </div>

                      <div style="font-size: 0.9rem">
                        <strong>{{ profile.name }}</strong> {{ $t('student.stundent_aula.aula_maxnote') }} <strong>{{ $t('student.student_projeto.projeto5') }}</strong>!
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-card
                  elevation="5"
                  class="my-3 rounded-lg"
                  :color="classColors[1].back"
                >
                  <v-list-item>
                    <v-list-item-avatar
                      class="my-2"
                      size="80"
                      style="border: 3px solid #1cfee8"
                      v-if="$vuetify.breakpoint.smAndUp"
                    >
                      <v-img :src="profile.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="font-weight-bold" style="font-size: 1rem">
                        {{ $t('student.student_recorde.recorde1') }}
                      </div>
                      <div style="font-size: 0.9rem">
                        <strong>{{ profile.name }}</strong>{{ $t('student.student_recorde.recorde5') }}<strong>{{ $t('student.student_recorde.recoder3') }}</strong>!
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>

              <!-- PROGRESSO DE AULAS ASSISTIDAS -->
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12" lg="6">
          <v-card elevation="5" class="rounded-xl pa-4 fill-height">
            <!-- <v-card-title>
              <v-avatar size="35" class="mr-1">
                <img src="../../assets/conquistas.png" />
              </v-avatar>
              Atividades da Turma
            </v-card-title> -->

            <v-col cols="12" class="mx-auto">
              <v-row>
                <v-col cols="12">
                  <v-img
                    height="280"
                    src="http://www.makereducacional.com.br/astronaut/gameevent/Games And Events-05.svg"
                    class="rounded-lg"
                  >
                    <span
                      class="
                        float-left
                        white
                        blue--text
                        font-weight-bold
                        py-1
                        px-4
                        my-3
                        mx-4
                        rounded-pill
                      "
                      style="font-size: 1rem"
                    >
                      {{ $t('student.student_recorde.recoder4') }}
                    </span>
                  </v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      profile: {},
      classColors: [
        { light: "#FD80CD", dark: "#E245A6", back: "rgba(253, 128, 205, 0.3)" },
        { light: "#1CFEE8", dark: "#089790", back: "rgba(28, 254, 232, 0.3)" },
        { light: "#F9C228", dark: "#CF9C0E", back: "rgba(249, 194, 40, 0.3)" },
        { light: "#FD80CD", dark: "#E245A6", back: "rgba(253, 128, 205, 0.3)" },
      ],
    };
  },
  created() {
    this.profile = this.$parent.$parent.$parent.friend;
  },
  methods: {},
};
</script>

<style lang="css" scoped></style>
